import React from 'react'

export const Footer = () => {
  return (
    <>
     <div className='footer'>
       <div className='container'>
         <div className='row'>
           <div className='col-md-12'>
            <div className='footer-innr'>
                <p>আরও জানতে ডাউনলোড করুন ইকরা অ্যাপ</p>
                <a href='https://play.google.com/store/apps/details?id=com.purpledigit.iqra'>ক্লিক করুন</a> 
            </div>
           </div>
         </div>
       </div>
     </div>
    </>
  )
}
