import React from 'react';

export const NoPageFound = () => {
  return (
    <>
      <div className='error-page-wrp'>
        <div className='error-page-dsc'>
            <h1>404 Error</h1>
            {/* <img src='./images/404_page_cover.jpg' alt='Error Images' /> */}
            {/* <span>Page Not Found</span> */}
            <i class="ri-alert-line"></i>
            <a href='/' className="btn btn-primary mt-4 back404">Back To Home</a>
        </div>
      </div>
    </>
  )
}
