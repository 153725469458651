import React from 'react';


export const Header = () => {
    const date = new Date();
    const options = {year: 'numeric', month: 'long', day: 'numeric',weekday:'long' };
   // const formattedDate = date.toLocaleDateString('en-US', options);
    const formattedDate = date.toLocaleDateString('bn-BD', options);
   
    return (
        <>
        <header className='header' style={{ backgroundImage: 'url(../../images/header-bg.png)'}}>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='header-wrp'>
                            <div className='logo'>
                                <a href='/'>
                                <img src='./images/logo.png'alt='Logo' />
                                </a>
                            </div>
                            <div className='header-dsc'>
                                <h2> সেহেরী ও ইফতার সময় সূচি</h2>
                                <strong>{formattedDate}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </>
    )
}
