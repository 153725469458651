import './App.css';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './pages/Home';
import { NoPageFound } from './pages/404';

function App() {
  return (
    <>
     <Router>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path="*" component={NoPageFound}/>
        </Switch>
     </Router>
    </>
  );
}

export default App;
