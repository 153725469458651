import React, { useEffect, useState } from 'react';
import ListTableData from '../ListTable/ListTableData';
import Table from 'react-bootstrap/Table';
import { replaceNumbers, timeFormatter } from '../../utils/timeFormatter';
import { dateFormatter } from '../../utils/dateFormatter';

export const ListTable = ({timesList, loading}) => {

  const[today,setToday] = useState(dateFormatter());
  
  return (
    <>
      {
        loading ? <div>loading...</div> : 
		    <div className='list-table-wrp'>
          <div className='list-table-scroll'>
            <Table striped bordered hover>
                  <tbody>
                  { timesList && timesList.map((items,index)=>(
                    <tr key={index} className={today === items?.date_en ? 'selected' : ''}>
                      <td>{replaceNumbers(items.day_of_ramadan)}</td>
                      <td>{replaceNumbers(items.shehri_end_time.slice(11,16))}</td>
                      <td>{replaceNumbers(new Date(items.iftar_time).toLocaleTimeString().slice(0,4))}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
          </div>
        </div>
      }
    </>
  )
}
