import React from 'react';
// import TabListData from '../TabList/TabListData';


export const TabList = () => {
  return (
    <>
     <div className='tab-list-wrp'>
        <div className='tab-list-innr'>
            <div className='tab-list'>
                <ul>
                    {/* {TabListData.map((item,value)=>(
                      <li key={value}>
                        <span>{item.date}</span>
                        <p>{item.bar}</p>
                     </li>
                    ))} */}
                    <li><span>রমজান</span></li>
                    <li><span>সেহেরীর শেষ সময়</span></li>
                    <li><span>ইফতার</span></li>
                </ul>
            </div>
        </div>
     </div>
    </>
  )
}
