import React from 'react'
import { replaceNumbers } from '../../utils/timeFormatter';
import moment from 'moment/moment';

export const IftarTime = ({sehriTime1,iftarTime1}) => {

    let today = new Date();
    let isoString = today.toISOString();
    let formattedDate = isoString.slice(0, 10);

    let now = moment().format('YYYY/MM/DD hh:mm:ss A');

    let iftarTime = moment(`${formattedDate} ${iftarTime1}`, 'YYYY/MM/DD hh:mm:ss A');

    if (Date.now() > iftarTime.toDate().getTime()) {
      // Use the setDate() method to set the date to the next day
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      let isoString2  = currentDate.toISOString();
      let nextDay = isoString2.slice(0,10)
      // If the current time is after iftar time, set the iftar time to the next day
      iftarTime = moment(`${nextDay} ${iftarTime1}`, 'YYYY/MM/DD hh:mm:ss A');
    }
    
    // Calculate the difference between iftar and sehri times in milliseconds
    let diffIftarMs = iftarTime.diff(now);

    // Convert the difference to hours and minutes
    let diffIftarDuration = moment.duration(diffIftarMs);
    let diffIftarHours = Math.abs(Math.floor(diffIftarDuration.asHours()));
    let diffIftarMinutes = Math.abs(diffIftarDuration.minutes());


    return (
    <>
      <div className='iftar-time-wrp'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                  <div className='iftar-time-innr'>
                    {/* <><h4>ইফতারের জন্য সময় বাকি আছে</h4> <span>{replaceNumbers(diffIftarHours.toString())}:{replaceNumbers(diffIftarMinutes.toString())} মিনিট</span></> */}
                    {/* <><h4>ইফতারের জন্য সময় বাকি আছে</h4> <span>{replaceNumbers(diffIftarHours.toString())} ঘণ্টা {replaceNumbers(diffIftarMinutes.toString())} মিনিট</span></> */}

                    </div>
                    <div className='iftar-time-btm'>
                        {/* <span>সেহরীর শেষ ‍সময় {replaceNumbers(sehriTime1.slice(0,5))} মিনিট</span> 
                            <span>ইফতার {replaceNumbers(iftarTime1.slice(0,5))} ‍মিনিট</span>
                        */}
                        <span>সেহরীর শেষ ‍সময় {replaceNumbers(sehriTime1.slice(11,16))} মিনিট</span>
                        
                        <span>ইফতার সময় {replaceNumbers(new Date(iftarTime1).toLocaleTimeString().slice(0,4))} ‍মিনিট</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}
